import React, { useCallback, useState } from "react";
import {
  View,
  StyleSheet,
  Modal,
  Image,
  ActivityIndicator,
  TouchableOpacity,
} from "react-native";
import Text from "../../../components/Text";
import { useHistory } from "react-router-dom";
import { Colors, Routes } from "../../../configs";
import ButtonLinear from "../../../components/Buttons/ButtonLinear";
import ModalChangePlan from "../../../components/ConsultDetail/Modals/ModalChangePlan";
import ModalSendReason from "../../../components/ConsultDetail/Modals/ModalSendReason";
import AdditionalItem from "../../../components/ConsultDetail/AdditionalItem";

import { dateTimeService } from "../../../import";
import { FAKE_EMAIL_REGEX } from "../../../models/constants";
import locales_es from "../../../locales/es";
import { CommonStyles } from "../../../styles/CommonStyles";
import ListButton from "../../../components/ListButton";
import { ConsultsType } from "../../../configs/Const";
import avatarProfileIcon from "../../../images/avatar/ic_account_normal1.png";
import recetasIcon from "../../../images/svg/recetas.svg";
import prescripcionesIcon from "../../../images/svg/preincipiones.svg";


import { SOURCE_ICON } from "../../../images";
import Card from "../../../components/Card";
import HeaderButton from "../../../components/HeaderButton";
import { getStatusBarHeight } from "react-native-iphone-x-helper";
import usePatientProfilePage from "../shared/usePatientProfilePage";
import  PatientMedicalInsurancesList  from "../../../components/PatientMedicalInsurances";
import FloatingButtonRcta from "../../../utils/buttom/FloatingButtonRcta";


interface PatientProfileDesktopProps {}

const PatientProfileDesktop = (props: PatientProfileDesktopProps) => {
  const history = useHistory();
  const {
    goBack,
    userData,
    patientId,
    readOnly,
    onUserDelete,
    onUserEdit,
    onCreatePrescription,
    visible,
    close,
    translateY,
    visibleModalChange,
    closeChange,
    medicalInsurances,
    insurancePermission,
    medicinePermission,
    userStatusEnabled
  } = usePatientProfilePage();


  // Acciones de boton flotante
  const actions = [
    {
      text: "Prescripciones",
      icon: prescripcionesIcon,
      name: "bt_prescripciones",
      onPress: () => onPencilPress(),


    },
    {
      text: "Recetas",
      icon: recetasIcon,
      name: "bt_recetas",
      onPress: () => onCreatePrescription(),


    }
  ];

  const onPencilPress = useCallback(() => {
    history.push(`/${Routes.AddCertificate}`);
  }, []);

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <View style={styles.headerWrapper}>
          <HeaderButton onPress={goBack} />
          <Text size={32} lineHeight={36} bold>
            {locales_es.patientProfile_page.title}
          </Text>
        </View>
        {userData && (
          <View style={styles.userDataWrapper}>
            <View>
              <Image
                source={
                  userData.full_profile_image
                    ? { uri: userData.full_profile_image }
                    : avatarProfileIcon
                }
                style={{ width: 50, height: 50, borderRadius: 25 }}
              />
            </View>
            <View>
              <Text size={20} lineHeight={24} semibold marginRight={32}>
                {userData.name} {userData.lastname}
              </Text>
              {userData.gender && userData.gender.name && (
                <Text size={18} lineHeight={22} bold marginRight={32}>
                  {userData.gender.name}
                </Text>
              )}
            </View>
            {!readOnly && (
              <View>
                <ButtonLinear
                  placeholder
                  colors={[Colors.RedNeonFuchsia, Colors.Tomato]}
                  styleButton={{ flex: 1 }}
                  style={styles.inactiveButton}
                  white={true}
                  title={"Inactivar Paciente"}
                  onPress={onUserDelete}
                />
              </View>
            )}
          </View>
        )}
        <View style={styles.cardWrapper}>
          <View style={styles.cardItem}>
            <Card
              header={{
                icon: SOURCE_ICON.doctor,
                title: locales_es.patientProfile_page.profile_information,
                action: !readOnly && (
                  <TouchableOpacity onPress={onUserEdit}>
                    <Image
                      source={{ uri: SOURCE_ICON.edit, width: 20, height: 20 }}
                      style={styles.cardAction}
                    />
                  </TouchableOpacity>
                ),
              }}
            >
              {!userData && <ActivityIndicator />}
              {userData && (
                <View>
                  <AdditionalItem
                    title={locales_es.fullname}
                    value={userData.name}
                  />
                  <AdditionalItem
                    title={locales_es.lastname}
                    value={userData.lastname}
                  />
                  <AdditionalItem
                    title={locales_es.patientProfile_page.identificationType}
                    value={userData.identification_type.name}
                  />
                  <AdditionalItem
                    title={locales_es.patientProfile_page.identification}
                    value={userData.identification}
                  />
                  <AdditionalItem
                    title={locales_es.patientProfile_page.phone_number}
                    value={userData.cell_phone}
                  />
                  <AdditionalItem
                    title={locales_es.email}
                    value={
                      !FAKE_EMAIL_REGEX.test(userData.email) && userData.email
                    }
                  />
                  <AdditionalItem
                    title={locales_es.birthDate}
                    value={dateTimeService.reverseAPIStringDate(
                      userData.birth_date
                    )}
                  />
                </View>
              )}
            </Card>
          </View>

          <View style={styles.cardItem}>
            {! userData && insurancePermission &&
              <Card
              header={{
                icon: SOURCE_ICON.exp,
                title: locales_es.patientProfile_page.insurance_information,
              }}
              >
                <ActivityIndicator />
              </Card>

            }
            { userData && insurancePermission &&
              <View>
                <PatientMedicalInsurancesList
                  patientMedicalInsurances={medicalInsurances}
                  onMedicalInsuranceSelect={()=>{}}
                  edit={insurancePermission}
                  onUserEdit={onUserEdit}
                />
              </View>
            }
            <Card
              header={{
                icon: SOURCE_ICON.exp,
                title: locales_es.patientProfile_page.prescriptions,
              }}
            >
              <ListButton
                title={locales_es.prescriptions}
                onPress={() =>
                  history.push(`/${Routes.PatientPrescriptions}`, {
                    patientId,
                    prescriptionType: ConsultsType.Prescription,
                  })
                }
              />
              <ListButton
                title={locales_es.otherPrescriptions}
                onPress={() =>
                  history.push(`/${Routes.PatientCertificates}`, {
                    patientId,
                    prescriptionType: ConsultsType.Certificate,
                  })
                }
              />
            </Card>
          </View>

        </View>
        <View style={styles.cardWrapper}>
          <View style={styles.cardItemFull}>
            <Card
              header={{
                icon: SOURCE_ICON.eyeOn,
                title: locales_es.patientProfile_page.notes,
                action: !readOnly && (
                  <TouchableOpacity onPress={onUserEdit}>
                    <Image
                      source={{ uri: SOURCE_ICON.edit, width: 20, height: 20 }}
                      style={styles.cardAction}
                    />
                  </TouchableOpacity>
                ),
              }}
            >
              <View style={{ paddingHorizontal: 24 }}>
                {userData === null ? (
                  <ActivityIndicator style={CommonStyles.activityIndicator} />
                ) : userData && userData.comment ? (
                  <Text size={15} lineHeight={18}>
                    {userData.comment}
                  </Text>
                ) : (
                  <Text size={15} lineHeight={18}>
                    Sin observaciones.
                  </Text>
                )}
              </View>
            </Card>
          </View>
        </View>
      </View>
      {!readOnly && medicinePermission && userStatusEnabled && (
      <View style={{ flex: 1 }}>
        <FloatingButtonRcta actions={actions} />
      </View>
      )}

      {visibleModalChange ? (
        <Modal
          visible={true}
          onRequestClose={closeChange}
          transparent
          animationType={"fade"}
        >
          <ModalChangePlan close={closeChange} />
        </Modal>
      ) : null}
      {visible ? (
        <Modal
          visible={true}
          onRequestClose={close}
          transparent
          animationType={"fade"}
        >
          <ModalSendReason close={close} translateY={translateY} />
        </Modal>
      ) : null}
    </View>
  );
};

export default PatientProfileDesktop;

const styles = StyleSheet.create({
  container: { flex: 1, minHeight: "100%" },
  content: {
    minHeight: "100%",
    paddingBottom: 24,
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: getStatusBarHeight(),
  },
  cardWrapper: {
    display: "flex",
    flexDirection: "row",
    marginTop: 24,
    gap: 24,
    flexWrap: "wrap",
  },
  cardItem: { flex: 1, gap: 24, minWidth: 300, maxWidth: 600 },
  cardItemFull: { flex: 1, minWidth: 300, maxWidth: 1224},
  cardAction: { tintColor: "#00c1c1", color: "#00c1c1" },
  headerWrapper: { flexDirection: "row", gap: 16, alignItems: "center" },
  prescriptionActionWrapper: {
    position: "fixed",
    right: 24,
    bottom: 16,
  },
  prescriptionAction: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "capitalize",
  },
  prescriptionButton: {
    borderRadius: 20,
    height: 40,
    width: 40,
    marginTop: 0,
    marginLeft: 8,
  },
  prescriptionIcon: { width: 20, height: 20, tintColor: "white" },
  userDataWrapper: {
    marginTop: 32,
    marginBottom: 16,
    flexDirection: "row",
    gap: 16,
    alignItems: "center",
    flexWrap: "wrap",
  },
  inactiveButton: { marginTop: 0, height: 40, width: 180 },
  defaultStyle: {
    padding: 10,
    backgroundColor: 'gray',
  },
  customStyle: {
    backgroundColor: 'blue',
  },

});
